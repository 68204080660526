import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [];

  scrollListener;
  side;


  connect() {
    this.side = this.element.getBoundingClientRect().left < window.innerWidth/2 ?
      1 : -1;

    this.scrollListener = this.onScroll.bind(this);
    document.addEventListener('scroll', this.scrollListener, { //wheel
      capture: false, passive: true
    });
  }

  onScroll(evt) {
    let bounds = this.element.getBoundingClientRect();
    if(bounds.top < window.innerHeight && bounds.top > -300) {
      let positionY = bounds.top + window.scrollY;
      let y = positionY <= window.innerHeight*2/3 ?
        window.scrollY : window.innerHeight*2/3 - bounds.top;

      let transform = Math.round(y * 2) * this.side; // /16

      this.element.style.transform = "translate(" + transform + "px," + (y / 6) + "px)";
    }
  }
}