import { Controller } from "stimulus"
// Depends on https://github.com/ganlanyuan/tiny-slider
import { tns } from "tiny-slider/src/tiny-slider"

export default class extends Controller {
  static targets = ['canvas'];

  sliderObject;


  connect() {

    this.sliderObject = tns({
      container: this.canvasTarget,
      items: 1,
      center: true,
      slideBy: 1,
      arrowKeys: true,
      controls: false,
      mouseDrag: true,
      nav: true,
      navPosition: 'bottom',
      loop: true,
      rewind: false,
      lazyload: true,
      swipeAngle: 70,
      responsive: {
        0: { edgePadding: 30, gutter: 10 },
        600:  { edgePadding: 100, gutter: 20 },
        850:  { edgePadding: 200, gutter: 20 },
        1100: { edgePadding: 0, items: 2 },
        1900: { edgePadding: 300, items: 2 }
      }
    });

    // bind to index change event
    //this.sliderObject.events.on('indexChanged',this.onChange.bind(this));

  }

  navigate( e ) {
    var p = this.element.querySelector('.tns-outer').getBoundingClientRect();
    var middleX = p.left + (p.width/2);

    if( e.clientY > p.top && e.clientY < p.bottom ) {
      // left side click
      if( e.clientX >= p.left && e.clientX < middleX) {
        this.sliderObject.goTo('prev');
        // right side click
      } else if( e.clientX >= middleX && e.clientX < p.right) {
        this.sliderObject.goTo('next');
      }
    }
  }

  goTo( e ) {
    var index = e.target.dataset.index;
    this.sliderObject.goTo(index);
  }

  onChange(info) {
    var index = info.index -1;

  }

  disconnect() {
    this.sliderObject.destroy();
    this.sliderObject = null;
  }

}
