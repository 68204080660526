import Cookies from "js-cookie"
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [];

  connect() {

  }


  enable(e) {
    e.preventDefault();
    var isSecure = location.protocol === 'https:';
    //Cookies.set('cookie_eu_consented', true, { path: '/', expires: 365, secure: isSecure });
    Cookies.set('cookie_eu_consented', true, { path: '/', expires: 365, secure: isSecure })
    this.hide_banner();
    location.reload();
  }


  disable(e) {
    if (e != undefined) {
      e.preventDefault();
    }
    var isSecure = location.protocol === 'https:';
    Cookies.set('ga-disable-UA-149137993-1', true, { path: '/', expires: 365, secure: isSecure });
    Cookies.set('cookie_eu_consented', false, { path: '/', expires: 365, secure: isSecure });
    this.hide_banner();
    location.reload();
  }

  disableOnPrivacy(e) {
    if (e != undefined) {
      e.preventDefault();
    }
    var isSecure = location.protocol === 'https:';
    Cookies.set('ga-disable-UA-149137993-1', true, { path: '/', expires: 365, secure: isSecure });
    Cookies.set('cookie_eu_consented', false, { path: '/', expires: 365, secure: isSecure });

    alert("Google Analytics wurde deaktiviert");

    location.reload();
  }

  hide_banner() {
    if (this.element) {
      this.element.classList.add("hidden");
    }
  }

}

